/* Cursor when in edition mode */
.edition_mode {
  .gg_labels,
  .gg_labels .ql-editor > * {
    cursor: url(../deps/OpenSankey/css/cursors/pen-nib-slash.svg) 0 24, auto;
  }
}

.selection_mode {
  /* Style of ZDT handles top & bottom */
  .zdt_top_handle,
  .zdt_bottom_handle {
    cursor: ns-resize;
  }
  /* Style of ZDT handles left & right */
  .zdt_left_handle,
  .zdt_right_handle {
    cursor: ew-resize;
  }
}
