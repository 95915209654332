/*! @import url("colors/dark violet.css"); COLOR IMPORT */
/********** MAIN CONFIGURATION **********/

/* BASIC CONFIGURATION */
body {
  background-color: #f2f2f2;
  /*font-family:'Open Sans',sans-serif;*/
  font-size: 14px;
  letter-spacing: 0px;
  color: #000;
  font-family: system-ui, sans-serif;
}

/* Cursor when in selection mode */
.selection_mode {
  /* Hovering node & link in selection mode */
  .gg_nodes {
    cursor: grab;
  }

  .gg_nodes:active {
    cursor: grabbing;
  }

  /* Cursor when hovering with alt key pressed*/
  .tooltip_shown {
    cursor: url(cursors/comment-info.svg) 24 24, auto;
  }

  /* Hovering background in selection mode */
  /* #g_background{
	cursor : url(cursors/pen-nib.svg) 0 0, auto;
	} */

  /* Hovering link control points  in selection mode */
  #g_handlers,
  #grp_legend {
    cursor: grab;
  }

  #g_handlers:active,
  #grp_legend:active {
    cursor: grabbing;
  }

  /* Hovering link io handle in selection mode */
  .node_io.right {
    cursor: url(cursors/move_node_handler_right.drawio.svg) 0 0, auto;
  }
  .node_io.left {
    cursor: url(cursors/move_node_handler_left.drawio.svg) 90 0, auto;
  }

  .node_io.top {
    cursor: url(cursors/move_node_handler_top.drawio.svg) 0 90, auto;
  }
  .node_io.bottom {
    cursor: url(cursors/move_node_handler_bottom.drawio.svg) 0 0, auto;
  }

  /* Hovering legend in selection mode */
  .g_scale {
    cursor: "move";
  }
}
/* Since legend & legend handlers are outside DA, it can't have parent class .edition_mode or .selection_mode */
.legend_left_handle,
.legend_right_handle {
  cursor: ew-resize;
}

/* Cursor when in edition mode */
.edition_mode {
  #g_background,
  .gg_nodes {
    cursor: url(cursors/pen-nib.svg) 0 24, auto;
  }

  #g_handlers,
  .link {
    cursor: url(cursors/pen-nib-slash.svg) 0 24, auto;
  }

  /* Cursor when hovering with alt key pressed*/
  .tooltip_shown {
    cursor: url(cursors/comment-info.svg) 24 24, auto;
  }

  .node_io {
    cursor: url(cursors/move_node_handler.svg) 0 0, auto;
  }
}

/* ------------------------------------------------
 TOOLTIP
------------------------------------------------ */
.sankey-tooltip {
  background-color: white;
  border: 1px solid #66a593;
  border-radius: 4px;
  min-width: 250px;
  max-width: 600px;
  font-size: 12px;
  z-index: 1800;
  position: absolute;
  pointer-events: none;
}

.sankey-tooltip .title {
  font-weight: bold;
  text-align: center;
  background: #66a593;
  color: white;
}

.sankey-tooltip .subtitle {
  font-size: 1.2em;
}

.sankey-tooltip .tab-title {
  font-weight: bold;
  font-size: 1.1em;
}

.sankey-tooltip table {
  color: black;
}

.sankey-tooltip table thead th {
  color: #66a593;
  font-weight: bold;
}

.sankey-tooltip table th {
  padding: 0px;
}

.sankey-tooltip table td {
  padding: 4px;
}

.sankey-tooltip ul {
  margin-bottom: 0;
  padding-left: 10px;
}

/* React multiselect component */
.rmsc {
  --rmsc-radius: 6px !important;
  --rmsc-h: 2rem !important;
}

.rmsc .dropdown-container {
  height: 2rem !important;
}

/* React carroussel overrides */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 32px;
  width: 32px;
}
.carousel-control-prev {
  justify-content: start;
}
.carousel-control-next {
  justify-content: end;
}

.carousel-control-prev-icon:hover,
.carousel-control-prev-icon:focus,
.carousel-control-next-icon:hover,
.carousel-control-next-icon:focus {
  height: 32px;
  width: 32px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23668ea5'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23668ea5'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
